<template>
  <div class="marketplace-item-view" v-if="nft">
    <div class="top-bar">
      <div class="back-button mb-4" @click="$router.back()">
        <svg
          width="20"
          height="20"
          viewBox="0 0 14 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.93934 10.9393C0.353553 11.5251 0.353553 12.4749 0.93934 13.0607L10.4853 22.6066C11.0711 23.1924 12.0208 23.1924 12.6066 22.6066C13.1924 22.0208 13.1924 21.0711 12.6066 20.4853L4.12132 12L12.6066 3.51472C13.1924 2.92893 13.1924 1.97919 12.6066 1.3934C12.0208 0.807611 11.0711 0.807611 10.4853 1.3934L0.93934 10.9393ZM3 10.5H2L2 13.5H3V10.5Z"
            fill=" #3f81e0"
          />
        </svg>

        <span>Back</span>
      </div>

      <div class="item-name">
        <h3>{{ nft.name }}</h3>
      </div>
    </div>

    <div class="item row">
      <div class="marketplace-item-view-left card-box col-md-5">
        <div class="item-media">
          <img
            :src="require(`@/assets/img/marketplace/sale/${nft.edition}.png`)"
            alt=""
            srcset=""
          />
        </div>
      </div>

      <div class="marketplace-item-view-right col-md-7">
        <div class="row">
          <div class="card-box">
            <div class="card card-sale-info col-12">
              <div class="card-title">SALE INFO</div>

              <div class="content">
                <div class="price mb-3">
                  <div class="currency">
                    <img
                      :src="require(`@/assets/img/logo-polygon.png`)"
                      alt=""
                    />
                  </div>
                  <div class="amount">{{ toDivide(nft.properties.price) }}</div>
                  <div class="converted">
                    ($
                    {{
                      toDivide(
                        parseFloat(
                          nft.properties.price * getCoinPrice(3890)
                        ).toFixed(2)
                      )
                    }})
                  </div>
                </div>

                <button class="btn" disabled>Buy</button>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="card-box">
            <div class="card card-about col-12">
              <div class="content-row">
                <div class="box">
                  <h5>RARITY</h5>

                  <div class="content">
                    <div class="icon">
                      <img
                        :src="require(`@/assets/img/icon-rarity-${nft.properties.rarity}.svg`)"
                        alt=""
                      />
                    </div>
                    <span style="text-transform: capitalize">{{nft.properties.rarity}}</span>
                  </div>
                </div>

                <div class="box">
                  <h5>SPECIALIST</h5>

                  <div class="content">
                    <div class="icon">
                      <img
                        :src="
                          require(`@/assets/img/icon-specialist-${nft.properties.specialist.toLowerCase()}.svg`)
                        "
                        alt=""
                      />
                    </div>
                    <span>{{ nft.properties.specialist }}</span>
                  </div>
                </div>
              </div>

              <div class="content-row">
                <div class="box">
                  <h5>TYPE</h5>

                  <div class="content">
                    <div class="icon">
                      <img
                        :src="
                          require(`@/assets/img/filter-type-${nft.properties.type.toLowerCase()}.png`)
                        "
                        alt=""
                      />
                    </div>

                    <span>{{ nft.properties.type }}</span>
                  </div>
                </div>
              </div>

              <div class="content-row">
                <div class="box">
                  <h5>LEVEL</h5>

                  <div class="content">
                    <div class="icon">
                      <img
                        :src="require(`@/assets/img/icon-properties-level.svg`)"
                        alt=""
                      />
                    </div>

                    <span>{{ nft.properties.level }}</span>
                  </div>
                </div>

                <div class="box">
                  <h5>ENERGY</h5>

                  <div class="content">
                    <div class="icon">
                      <img
                        :src="
                          require(`@/assets/img/icon-properties-energy.svg`)
                        "
                        alt=""
                      />
                    </div>

                    <span>{{ 300 }}</span>
                  </div>
                </div>
              </div>

              <div class="content-row">
                <div class="box">
                  <h5>LUCK</h5>

                  <div class="content">
                    <div class="icon">
                      <img
                        :src="require(`@/assets/img/icon-properties-luck.svg`)"
                        alt=""
                      />
                    </div>

                    <span>{{ nft.properties.luck }}</span>
                  </div>
                </div>

                <div class="box">
                  <h5>EFFICIENCY</h5>

                  <div class="content">
                    <div class="icon">
                      <img
                        :src="
                          require(`@/assets/img/icon-properties-efficiency.svg`)
                        "
                        alt=""
                      />
                    </div>

                    <span>{{ nft.properties.efficiency }}</span>
                  </div>
                </div>

                <div class="box">
                  <h5>ENERGY EFFICIENCY</h5>

                  <div class="content">
                    <div class="icon">
                      <img
                        :src="
                          require(`@/assets/img/icon-properties-energy-efficiency.svg`)
                        "
                        alt=""
                      />
                    </div>

                    <span>{{ nft.properties.energyEfficiency }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MarketplaceItemView",
  data() {
    return {
      nft: null,
    };
  },
  computed: mapGetters(["nftArray", "getCoinPrice"]),
  methods: {
    toDivide: (number) => {
      return number.toString().replace(/.+?(?=\D|$)/, function (f) {
        return f.replace(/(\d)(?=(?:\d\d\d)+$)/g, "$1,");
      });
    },
  },
  mounted() {
    this.nft = this.nftArray.filter(
      (nft) => nft.dna == this.$route.params.id
    )[0];
  },
  watch: {
    /* eslint-disable */
    $route(to, from) {
      this.nft = this.nftArray.filter(
        (nft) => nft.dna == this.$route.params.id
      )[0];
    },
  },
};
</script>

<style lang="scss">
.marketplace-item-view {
  margin: 0 auto;
  max-width: 1148px;
  padding-top: 20px;

  @media screen and (max-width: 767.98px) {
    max-width: 456px;
  }

  .top-bar {
    .back-button {
      display: flex;
      align-items: center;
      cursor: pointer;

      svg {
        path {
          fill: #3f81e0;
        }
      }

      span {
        color: #3f81e0;
        padding-left: 8px;
        font-size: 18px;
      }
    }

    .item-name {
      margin-bottom: 32px;

      h3 {
        font-size: 28px;
        font-weight: 800;
      }
    }
  }

  .item {
    display: flex;
    flex-wrap: wrap;
  }

  &-left {
    .item-media {
      display: flex;
      align-items: center;
      background-image: url(../../assets/img/marketplace/marketplace-item-bg.png);
      border-radius: 20px;

      img {
        width: 100%;
        max-width: 456px;
        border-radius: 18px;
      }
    }
  }

  .card {
    &-sale-info {
      .card-title {
        font-weight: 800;
        font-size: 14px;
        color: #5c616d;
      }

      .content {
        .price {
          display: flex;
          align-items: center;
          font-weight: 500;

          .currency {
            display: flex;
            align-items: center;
            margin-right: 10px;

            img {
              max-width: 38px;
            }
          }

          .amount {
            font-size: 30px;
            font-weight: 700;
            margin-right: 12px;
            color: #5e5873;
          }

          .converted {
            color: #a49fb8;
          }
        }

        button {
          width: 160px;
          border-radius: 20px;
          text-transform: uppercase;
          font-size: 18px;
          font-weight: 800;

          &:disabled {
            background: #cdcdcd;
            cursor: not-allowed;
          }
        }

        .sold-out {
          font-size: 24px;
          font-weight: 500;
        }
      }
    }

    .content-row {
      display: flex;

      &:not(:last-of-type) {
        margin-bottom: 24px;
      }

      .box {
        display: flex;
        flex-direction: column;

        &:not(:last-of-type) {
          margin-right: 32px;
        }

        h5 {
          font-weight: 800;
          font-size: 14px;
          margin-bottom: 6px;
          color: #5c616d;
        }

        .content {
          display: flex;
          align-items: center;

          .icon {
            display: flex;
            justify-content: center;
            align-items: center;

            width: 40px;
            height: 40px;
            border-radius: 14px;
            background: #eff3f9;
            margin-right: 10px;

            img {
              height: 24px;
              max-width: 24px;
            }
          }

          span {
            font-weight: 800;
            font-size: 18px;
            color: #161d2f;
          }
        }
      }
    }
  }
}
</style>